//  background-color: ${(props) => (props.test2Color ? props.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.phone(css` background-color: green; `)}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};

import { styled, g } from "UIV2/base/styledMixins/styledMixins.js";

export const Container = styled.div`
    position: relative;
    width: 100%;
    /* z-index: +1; */
    & > div:nth-child(1) {
        z-index: 10;
    }
`;
export const Title = styled.div`
    position: relative;
    width: 100%;
    font-size: 1.6rem;
    font-weight: 700;
`;
export const Info = styled.div`
    position: relative;
    width: 100%;
    font-size: 1.3rem;
    margin-bottom: 2rem;
`;
export const PopArea = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 5rem;
    min-height: 5rem;
    background-color: white;
    z-index: 20000;
    padding: 2rem;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
`;
export const TextAreaPopArea2 = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 5rem;
    min-height: 4rem;
    background-color: white;
    z-index: 20000;
    padding: 2rem;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
`;
export const TokensArea = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;
export const TokenButton = styled.button`
    width: max-content;
    margin-right: 1rem;
    border-radius: 5px;
    padding: 0.2rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${g.getColor("primary")};
    background-color: ${(props) => (props.selected ? g.getColor("antBlue") : g.getColor("grey10"))};
    transition: all 250ms ease-in-out;

    &:hover {
        color: ${g.getColor("background")};
        background-color: ${g.getColor("grey30")};
    }
`;
export const TokenButtonRadio = styled.button`
    width: max-content;
    margin-right: 1rem;
    border-radius: 5px;
    padding: 0.2rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${g.getColor("primary")};
    background-color: ${(props) => (props.selected ? g.getColor("antBlue") : g.getColor("grey10"))};
    transition: all 250ms ease-in-out;
    pointer-events: ${(props) => (props.selected ? "none" : "default")};

    &:hover {
        color: ${g.getColor("background")};
        background-color: ${g.getColor("grey30")};
    }
`;

/**
 * 2nd Version
 */
export const LineDiv = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    min-height: 5rem;
    padding: 1rem 0;
    align-items: center;

    & > div:nth-child(1) {
        flex: 0 8rem;
        font-weight: 700;
        &:after {
            content: ":";
            position: absolute;
            right: 0;
        }
    }
    & > div:nth-child(2) {
        display: flex;
        padding-left: 0.5rem;
        flex: 1 auto;
    }
`;
export const PopCloser = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
`;
export const MarkTextArea = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 25px);
    z-index: 1;
    padding-top: 1.3rem;
    padding-left: 2.8rem;
    font-size: 1.4rem;
    color: rgba(0, 0, 0, 0);
    overflow-y: hidden;
    height: 4rem;
    pointer-events: none;

    & > mark {
        background-color: yellow;
        color: yellow;
    }
`;
